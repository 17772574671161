import React from "react";
import { ContentContainer } from "../styles/layout";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image"
import media from "styled-media-query";
import Obfuscate from 'react-obfuscate';


const BlockContainer = styled(ContentContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;

  h2 {
    font-size: ${(props) => props.theme.typography.size.lg};
  }

  ${media.greaterThan("medium")`
    flex-direction: row;
    h2 {
      font-size: ${(props) => props.theme.typography.size.xl};
    }  
  `}
`;

const ImgContainer = styled.div`
  width: 80%;
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing.lg};
  ${media.greaterThan("medium")`
    flex: 1;
    text-align: right;
    padding-right: ${(props) => props.theme.spacing.lg};
    margin-bottom: 0;
  `}
`;
const Content = styled.div`
  flex: 1;
  align-items: center;

  ${media.greaterThan("medium")`
    padding-left: ${(props) => props.theme.spacing.lg}
  `}
`;

const ContactDetails = styled.div`
  h3{
    margin: 0;
  }
  p{
    margin-top: 0;
  }
  address{
    font-style: normal;
  }
`;

export const Contact = () => (
  <BlockContainer>
    <ImgContainer>
      <StaticImage width={380}
      height={380} src="../images/office_location.png" alt="Praxis Anfahrt" />
    </ImgContainer>
    <Content>
      <h2>Kontakt</h2>
      <p>Um ein Erstgespräch zu vereinbaren oder bei noch offenen Fragen kontaktieren Sie mich gerne unter:</p>
      
      <ContactDetails>
        <h3>&#8212; Telefon</h3>
        <p><Obfuscate tel="+43 650 540 75 99" /></p>
        <h3>&#8212; Email</h3>
        <p><Obfuscate email="office@psychotherapie-hoffelner.at" /></p>
        <h3>&#8212; Praxis</h3>
        <p><address>Linsengasse 57, 9020 Klagenfurt</address></p>
        <p>Ich freue mich darauf, Sie/dich kennenzulernen!</p>
      </ContactDetails>
    </Content>
  </BlockContainer>
);

export default Contact