import * as React from "react"
import { Helmet } from "react-helmet"

import styled from "styled-components";
import About from "../components/About";
import Welcome from "../components/Welcome";
import Focus from "../components/Focus";
import Hero from "../components/Hero";
import Office from "../components/Office";
import Quote from "../components/Quote";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { Metadata } from "../components/Metadata";


// markup
const IndexPage = () => {
  return (
    <main>
      <Metadata/>
      <Hero/>
      <Quote/>
      <Welcome/>
      <Focus/>
      <About/>
      <Office/>
      <Contact/>
      <Footer/>
    </main>
  )
}

export default IndexPage
