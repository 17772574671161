import React from "react";
import { ContentContainer, FullWidthBackground } from "../styles/layout";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image"
import media from "styled-media-query";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

const OfficeContainer = styled(FullWidthBackground)`
  background-color: ${(props) => props.theme.colors.accents.forest};
  color: white;
`;

const BlockContainer = styled(ContentContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;  
  h2 {
    font-size: ${(props) => props.theme.typography.size.lg};
  }

  ${media.greaterThan("medium")`
    flex-direction: row;
    h2 {
      font-size: ${(props) => props.theme.typography.size.xl};
    }  
  `}
`;

const ImgContainer = styled.div`
  width: 100%;
  ${media.greaterThan("medium")`
    flex: 1;
    text-align: left;
  `}
`;
const Content = styled.div`
  flex: 1;
  align-items: center;

  ${media.greaterThan("medium")`
    padding-right: ${(props) => props.theme.spacing.lg}
  `}
`;

const Slider = styled(Swiper)`
  width: 100%;  
  max-width: 400px;
  .swiper-wrapper{
    align-items: center;
  }

  span.swiper-pagination-bullet {
    background-color: white;
  }

  .swiper-slide{
    display:flex;
    align-items: center;

    .slider-image{
      margin: auto;
    }
  }
`

export const Office = () => (
  <OfficeContainer>
    <BlockContainer>    
      <Content>
        <h2>Meine Praxis</h2>
        <p>Meine Praxis befindet sich in Klagenfurt in der Linsengasse 57. Der Nebeneingang durch das grüne Gartentor führt Sie zu mir.</p>
        <p>Für die Anreise mit dem Auto sind rund um die Praxis genügend Parkmöglichkeiten vorhanden (keine Kurzparkzone). An der Rizzibrücke findet sich die nächstgelegene Bushaltestelle (3 min Gehzeit).</p>
      </Content>
      <ImgContainer>
        <Slider
            loop={true}
            effect={"fade"}
            speed={2000}
            pagination={{
              clickable: true,
            }}
            centeredSlides={true}
            autoplay={{delay: 3000, disableOnInteraction: false}}
            fadeEffect={{ crossFade: true }} 
            modules={[EffectFade, Pagination, Autoplay]}
            className="mySwiper">
          
          <SwiperSlide>
            <StaticImage className="slider-image" width={500} src="../images/office/1.jpeg" alt="Office" />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage className="slider-image" width={500} src="../images/office/2.jpeg" alt="Office" />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage className="slider-image" height={300} src="../images/office/3.jpeg" alt="Office" />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage className="slider-image" height={300} src="../images/office/4.jpeg" alt="Office" />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage className="slider-image" height={300} src="../images/office/5.jpeg" alt="Office" />
          </SwiperSlide>
        </Slider>
        
        
      </ImgContainer>
    </BlockContainer>
  </OfficeContainer>
);

export default Office