import React from "react";
import styled from "styled-components";
import { ContentContainer, FullWidthBackground } from "../styles/layout";
import media from "styled-media-query";

const WelcomeContainer = styled(FullWidthBackground)`
  background-color: ${(props) => props.theme.colors.accents.sandLight};
  color: ${(props) => props.theme.colors.base};        
`;

const Content = styled(ContentContainer)`
  text-align: center;
  ${media.greaterThan("medium")`
    h2 {
      font-size: ${(props) => props.theme.typography.size.xl};
      width: 75%;
      margin-left: auto;
      margin-right: auto;
    }
    > p{
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }  
  `}
`;


const Welcome = ({ }) => {
  return (
    <WelcomeContainer>
      <Content>
        <h2>Herzlich Willkommen!</h2>
        <p>In meiner psychotherapeutischen Praxis arbeite ich mit Erwachsenen, jungen Erwachsenen, Kindern und Jugendlichen, sowie Mamas, Papas und deren Babys. Die Gespräche finden je nach Bedarf im Einzel-, Paar- oder Familiensetting statt. Neben der Psychotherapie begleite ich auch Selbsterfahrungsprozesse, sowie berufliche Themen in Form von Einzel- und Gruppensupervision.</p>
        <p>In unserer gemeinsamen therapeutischen Arbeit sorge ich für einen vertrauensvollen und geschützten Rahmen, in dem Sie und Ihre Anliegen gut Raum finden können.  Neben der Behandlung krankheitswertiger Störungen, wie Ängste, psychosomatische Leiden u.a., liegen meine Schwerpunkte auf bindungsorientierten Themen, Lebensübergangskrisen und Neuorientierungsprozessen, Überforderungs- und Erschöpfungszuständen, der Persönlichkeitsstärkung, sowie Frauen- und Familienthemen.</p>
      </Content>
    </WelcomeContainer>
  );
};


export default Welcome;
