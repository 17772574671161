import React from "react";
import { ContentContainer } from "../styles/layout";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image"
import media from "styled-media-query";


const BlockContainer = styled(ContentContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;

  h2 {
    font-size: ${(props) => props.theme.typography.size.lg};
  }

  ${media.greaterThan("medium")`
    flex-direction: row;
    h2 {
      font-size: ${(props) => props.theme.typography.size.xl};
    }  
  `}
`;

const ImgContainer = styled.div`
  width: 80%;
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing.lg};
  ${media.greaterThan("medium")`
    flex: 1;
    text-align: right;
    padding-right: ${(props) => props.theme.spacing.lg};
    margin-bottom: 0;
  `}
`;
const Content = styled.div`
  flex: 1;
  align-items: center;

  ${media.greaterThan("medium")`
    padding-left: ${(props) => props.theme.spacing.lg}
  `}
`;

export const About = () => (
  <BlockContainer>
    <ImgContainer>
      <StaticImage width={380}
      height={380} src="../images/about_me.png" alt="About me" />
    </ImgContainer>
    <Content>
      <h2>Über mich</h2>
      <p>Ich bin Klinische- und Gesundheits&shy;psychologin und Psychotherapeutin mit dem Schwerpunkt der Systemischen Familientherapie. Seit 2017 bin ich in freier Praxis in Klagenfurt tätig. Davor, und auch noch in den ersten Jahren neben der Praxis, vertiefte ich meine Qualifikationen in Teams psychiatrischer Rehabilitationszentren, psychologisch-psychotherapeutischer Ambulanzen und Lebensberatungsstellen.</p>
      <h3>Die wichtigsten Eckpunkte meiner Ausbildung umfassen:</h3>
      <ul>
        <li>Studium der Psychologie an der Alpen-Adria Universität in Klagenfurt mit Auslands&shy;studium in Managua, Nicaragua</li>
        <li>Postgraduale Ausbildungen zur Klinischen- und Gesundheits&shy;psychologin, Biofeedbacktherapeutin, u.a.</li>
        <li>Psychotherapeutisches Fachspezifikum in Systemischer Familientherapie</li>
        <li>Weiterbildung in Säuglings-, Kinder- und Jugendlichen-Psychotherapie</li>
        <li>Weiterbildung in Integrativer Bindungsorientierter Traumatherapie bei Säuglingen, Kleinkindern, Vorschulkindern (I.B.T.) und deren Bezugspersonen</li>
        <li>laufende Fort- und Weiterbildungen</li>
      </ul>
    </Content>
  </BlockContainer>
);

export default About