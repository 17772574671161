import React from "react";
import styled from "styled-components";
import { ContentContainer, FullWidthBackground } from "../styles/layout";
import media from "styled-media-query";

const QuoteContainer = styled(FullWidthBackground)`
  background-color: white;
`;

const Content = styled(ContentContainer)`
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;
  h2{
    font-size: ${(props) => props.theme.typography.size.md};
  }  

  ${media.greaterThan("medium")`
    max-width: 730px;
    h2 {
      font-size: ${(props) => props.theme.typography.size.lg};
      margin-left: auto;
      margin-right: auto;
    }
    > p{
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }  
  `}
`;

const Author = styled.span`
  color: ${(props) => props.theme.colors.accents.sandDark};
`

const Quote = ({ }) => {
  return (
    <QuoteContainer>
      <Content>
        <h2>"Jenseits von richtig und falsch gibt es einen Ort. Hier können wir einander begegnen"</h2>
        <p><Author>- Rumi -</Author></p>
      </Content>
    </QuoteContainer>
  );
};


export default Quote;
