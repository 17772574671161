import React from "react";
import { ContentContainer, FullWidthBackground } from "../styles/layout";
import styled from "styled-components";
import { darken } from "polished";
import media from "styled-media-query";

const BlockContainer = styled(FullWidthBackground)`
  background-color: ${(props) => props.theme.colors.accents.sand};
`;

const Content = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${(props) => props.theme.spacing.lg};
  padding-bottom: ${(props) => props.theme.spacing.lg};

  ${media.greaterThan("medium")`
    flex-direction: row;
    padding-top: ${(props) => props.theme.spacing.md};
    padding-bottom: ${(props) => props.theme.spacing.md};
  `}
`;

const FocusItem = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: ${(props) => props.theme.spacing.md} 0;
  text-align: center;
  color: ${(props) => props.theme.colors.base};
  h3 {
    font-size: ${(props) => props.theme.typography.size.md};
    font-weight: normal;
    margin: 0 0 ${(props) => props.theme.spacing.md};
  }

  ${media.greaterThan("medium")`
    max-width: 33.33%;    
    padding: ${(props) => props.theme.spacing.lg};
    h3{
      font-size: ${(props) => props.theme.typography.size.lg};
    }
  `}
`;

const Line = styled.hr`
  background-color: ${(props) => darken(0.1, props.theme.colors.accents.sand)};
  height: 2px;
  border: 0;
  width: 50px;
  margin: auto;
`;

const content = [
  {heading:"Psychotherapie", description:"bei der Bewältigung von psychischen Problemen, Leidenszuständen und Lebenskrisen"},
  {heading:"Supervision", description:"als lösungs- und zielorientierte Beratung zur Sicherung und Verbesserung der beruflichen Prozesse und der eigenen beruflichen Gesundheit"},
  {heading:"Selbsterfahrung", description:"zum Erkennen eigener Fähigkeiten, zum Aufbau von Ich-Stärke und Selbstvertrauen und zur Weiterentwicklung und Entfaltung der ganz individuellen Persönlichkeit"}
]
export const Focus = () => (
  <BlockContainer>
    <Content>
      {content.map((i) => (
        <FocusItem>
          <h3>{i.heading}</h3>
          <Line />
          <p>{i.description}</p>
        </FocusItem>
      ))}
    </Content>
  </BlockContainer>
);


export default Focus
