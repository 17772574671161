import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { FullWidthBackground, ContentContainer } from "../styles/layout";
import media from "styled-media-query";
import { GatsbyImage } from "gatsby-plugin-image";
import {Logo} from "./Logo";

const HeroContainer = styled(FullWidthBackground)`
  display: grid;
  max-width: 1920px;
`;

const HeroContentContainer = styled.div`
  display: grid;
  grid-area: 1/1;
  position: relative;
  place-items: center;
  background-color: rgba(255,255,255,0.6);
`;


const BackgroundImage = styled(GatsbyImage)`
  grid-area: 1/1;
  min-height: 480px;
  max-height: 920px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;
const Col = styled.div`
  display: flex;
  flex: 0.5;
  text-align: right;
  padding-left: ${(props) => props.theme.spacing.lg};
`;

const HeroContent = ({ }) => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
    }
  `);
  return (
    <HeroContainer>
      <BackgroundImage image={data.heroImg.childImageSharp.gatsbyImageData} />
      <HeroContentContainer>
        <Row>
          <Col>
            <Logo/>
          </Col>   
        </Row>
      </HeroContentContainer>
    </HeroContainer>
  );
};

const Hero = styled(HeroContent)`
  width: 100%;
  background-position: center center;
  background-repeat: none;
  background-size: cover;
`;

export default Hero;
